const isProduction = process.env.NODE_ENV === 'production';

export const BASE_URL = isProduction ? "https://api.neusort.com/" : "http://localhost:5005/";

export const HOST_NAMES = [
  'neusort.com',
  'hyrr.app',
  'techyrr.com',
  'localhost',
];

export const TEST_IPS = ['20.193.143.183'];

export const adminViewAssignmentDir = "/opt/admin/assignments/"

export const licenceTypes = {
  "v1": "Ultra",
  "v2": "Pro",
}