export const sendAssignmentSubjectV1 = () => {
  return `Next Steps: Assignment Submission - \${name}`;
}

export const sendAssignmentTemplateV1 = (job_role, company_name) => {
  return `<p>Dear \${name},</p><p>I hope this email finds you well.</p><p>Couple of weeks back you filled out this form for the ${job_role} opportunity at ${company_name}.</p>As part of our assessment process, we have attached an assignment for you to complete. This assignment will help us assess your skills and suitability for the role.</p><p><strong>Please find the assignment attached in PDF format.</strong></p><p><br></p><p><strong>Instructions:</strong></p><ol><li>Review the assignment carefully.</li><li>Complete all tasks as outlined in the document.</li><li>Ensure your work is well-organized and clearly presented.</li></ol><p><br></p><p><strong>Submission Deadline:</strong> \${deadline}</p><p>Please submit your completed assignment by submitting your github repo here: <a href="\${link}" rel="noopener noreferrer" target="_blank">\${link}</a> no later than the deadline mentioned above.</p><p>Should you have any questions or require any clarification regarding the assignment, feel free to reach out to us.</p><p>We look forward to receiving your completed assignment and appreciate the effort you put into this task.</p><h5 class="ql-align-center"><br></h5><h4 class="ql-align-center">Best regards,</h4><h4 class="ql-align-center">${company_name}</h4>`;
}

export const interviewScheduleSubjectV1 = () => {
  return `Interview Schedule - \${name}`;
}

export const interviewScheduleTemplateV1 = (job_role, company_name) => {
  return `<h1 class="ql-align-center">Interview Schedule</h1><p class="ql-align-center">We are pleased to invite you to schedule an interview for the role of ${job_role} at ${company_name}. Please use the link below to select a convenient time for your interview.</p><p class="ql-align-center">Schedule Interview Link: <a href="\${scheduleLink}" rel="noopener noreferrer" target="_blank">\${scheduleLink}</a></p><h4 class="ql-align-center">If you have any questions, feel free to contact us at <b>contact@\${source}.com</b></h4>`;
}

export const interviewInviteSubjectV1 = () => {
  return `Interview Invite - \${name}`;
}

export const interviewInviteTemplateV1 = (job_role, company_name) => {
  return `<h1 class="ql-align-center">Interview Invitation</h1><p class="ql-align-center">We are pleased to invite you to an interview for the role of ${job_role} at ${company_name}. Below are the details of your interview:</p><ul><li class="ql-align-center"><strong>Date:</strong> \${date}</li><li class="ql-align-center"><strong>Timing:</strong> \${startTime} - \${endTime}</li><li class="ql-align-center"><strong>Interview Link: </strong><a href="\${interviewLink}" rel="noopener noreferrer" target="_blank">Link</a></li></ul><p class="ql-align-center">We look forward to speaking with you.</p><p class="ql-align-center">Best regards,</p><p class="ql-align-center">The Hiring Team</p><h4 class="ql-align-center">© 2024 \${source}. All rights reserved.</h4>`;
}

export const interviewScheduleSubjectV2 = () => {
  return `Interview Schedule - \${name}`;
}

export const interviewScheduleTemplateV2 = (job_role, company_name) => {
  return `<h1 class="ql-align-center">Interview Schedule</h1> <p class="ql-align-center"> We are pleased to invite you for an interview for the position of ${job_role} at ${company_name}. </p> <p class="ql-align-center"> Please select your preferred interview time using the link below: <br> <a href="\${scheduleLink}" rel="noopener noreferrer" target="_blank">\${scheduleLink}</a> </p> <p class="ql-align-center"> <strong>Important Note:</strong> <br> The interview will be based on the attached project. Please review it thoroughly before the interview, as understanding this project is essential for answering the interview questions. </p> <p class="ql-align-center"> Questions? Contact us at: <strong>contact@\${source}.com</strong> </p>`;
}

export const interviewInviteSubjectV2 = () => {
  return `Interview Invite - \${name}`;
}

export const interviewInviteTemplateV2 = (job_role, company_name) => {
  return `<h1 class="ql-align-center">Interview Invitation</h1><p class="ql-align-center">We are pleased to invite you to an interview for the role of ${job_role} at ${company_name}. Below are the details of your interview:</p><ul><li class="ql-align-center"><strong>Date:</strong> \${date}</li><li class="ql-align-center"><strong>Timing:</strong> \${startTime} - \${endTime}</li><li class="ql-align-center"><strong>Interview Link: </strong><a href="\${interviewLink}" rel="noopener noreferrer" target="_blank">Link</a></li></ul><p class="ql-align-center">We look forward to speaking with you.</p><p class="ql-align-center">Best regards,</p><p class="ql-align-center">The Hiring Team</p><h4 class="ql-align-center">© 2024 \${source}. All rights reserved.</h4>`;
}

export const interviewTemplatesV1 = (job_role, company_name) => {
  job_role = job_role || "<b style='color: red;'>***Enter Job Role***</b>";
  company_name = company_name || "<b style='color: red;'>***Enter Company Name***</b>";
  return {
    "sendAssignmentTemplate": sendAssignmentTemplateV1(job_role, company_name),
    "interviewScheduleTemplate": interviewScheduleTemplateV1(job_role, company_name),
    "interviewInviteTemplate": interviewInviteTemplateV1(job_role, company_name),
    "sendAssignmentSubject": sendAssignmentSubjectV1(),
    "interviewScheduleSubject": interviewScheduleSubjectV1(),
    "interviewInviteSubject": interviewInviteSubjectV1(),
  }
}

export const interviewTemplatesV2 = (job_role, company_name) => {
  job_role = job_role || "<b style='color: red;'>***Enter Job Role***</b>";
  company_name = company_name || "<b style='color: red;'>***Enter Company Name***</b>";
  return {
    "interviewScheduleTemplate": interviewScheduleTemplateV2(job_role, company_name),
    "interviewInviteTemplate": interviewInviteTemplateV2(job_role, company_name),
    "interviewScheduleSubject": interviewScheduleSubjectV2(),
    "interviewInviteSubject": interviewInviteSubjectV2(),
  }
}

export const requiredVariables = {
  sendAssignmentTemplate: ["name", "deadline", "link"],
  interviewScheduleTemplate: ["scheduleLink", "source"],
  interviewInviteTemplate: ["date", "startTime", "endTime", "interviewLink", "source"],
};

export const defaultVariables = {
  sendAssignmentTemplate: ["Enter Job Role", "Enter Company Name"],
  interviewScheduleTemplate: ["Enter Job Role", "Enter Company Name"],
  interviewInviteTemplate: ["Enter Job Role", "Enter Company Name"],
}