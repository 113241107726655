import { CircularProgress } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import AdminSidebar from "customComponents/AdminComponents/AdminSidebar";
import AdminNavbar from "customComponents/AdminComponents/AdminNavbar";

function AdminLayout({ component }) {
  const [isLoading, setIsLoading] = useState(true);
  const { user, initializing, authorized } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (initializing) return;
    if (!user) {
      navigate("/signin");
      return;
    }
    setIsLoading(false);
  }, [user, initializing, authorized]);

  if (isLoading)
    return (
      <div className="loading-container">
        <div className="loading-box">
          <h2 className="loading-text">Running Authorization Checks</h2>
          <CircularProgress style={{ height: "40px", width: "40px" }} />
        </div>
      </div>
    );

  return (
    <div className="h-dvh flex">
      {user?.claims?.admin && authorized ? (
        <div className="bg-white flex flex-1 flex-row">
          <AdminSidebar />
          <div className="flex flex-1 flex-col">
            {component}
          </div>
        </div>
      ) : (
        <div className="unauthorized-container">
          <h2 className="unauthorized-heading">You are not authorized.</h2>
          <button
            className="logout-button"
            onClick={async () => {
              await signOut(auth);
              navigate("/signin");
            }}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default AdminLayout;
