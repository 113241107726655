import { Close, PersonAdd, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function InviteAdmin() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    licence: ""
  });
  const [open, setOpen] = useState(false);
  const { user } = UserAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    const restrictedDomains = [
      "mail.com", "gmail.com", "hotmail.com", "yahoo.com",
      "outlook.com", "icloud.com", "aol.com", "zoho.com",
      "protonmail.com", "yandex.com", "live.com", "me.com",
      "msn.com", "gmx.com", "inbox.com"
    ];
    const domain = email.split("@")[1];
    return !restrictedDomains.includes(domain);
  };

  const sendInvite = async () => {
    if (!user) {
      toast.error("Error, please sign in first");
      return;
    }

    if (!formData.email || !formData.name || !formData.licence) {
      toast.error("Please fill in all required fields");
      return;
    }

    if (!isValidEmail(formData.email)) {
      toast.error("Invalid email domain. Please use a different email.");
      return;
    }

    try {
      const idTokenResult = await tokenResult(user);
      const response = await fetch(`${BASE_URL}super-admin/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idTokenResult.token}`,
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormData({ email: "", name: "", licence: "v1" });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PersonAdd />}
        style={{ color: "white" }}
        sx={{
          width: "200px",
          borderRadius: 2,
          textTransform: "none",
          boxShadow: 2,
          "&:hover": {
            boxShadow: 4,
          },
        }}
        onClick={handleClickOpen}
      >
        Invite Admin
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 24,
          }
        }}
      >
        <DialogTitle sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "divider",
          pb: 2
        }}>
          <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PersonAdd color="primary" />
            Invite New Admin
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText sx={{ mb: 3 }}>
            Please fill in the details below to send an admin invitation.
          </DialogContentText>

          <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <TextField
              required
              fullWidth
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              variant="outlined"
              placeholder="Enter admin's full name"
            />

            <TextField
              required
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              placeholder="Enter business email address"
              helperText="Personal email domains are not allowed"
            />

            <FormControl fullWidth>
              <InputLabel id="licence-type-label">Licence Type</InputLabel>
              <Select
                required
                labelId="licence-type-label"
                name="licence"
                value={formData.licence}
                onChange={handleChange}
                label="Licence Type"
                style={{ "height": "44px" }}
              >
                <MenuItem value="v1">V1 Licence</MenuItem>
                <MenuItem value="v2">V2 Licence</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions sx={{
          p: 3,
          borderTop: 1,
          borderColor: "divider",
          gap: 1
        }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ color: "white", background: "rgb(255 73 73)" }}
            startIcon={<Close />}
          >
            Cancel
          </Button>
          <Button
            onClick={sendInvite}
            variant="contained"
            style={{ color: "white" }}
            startIcon={<Send />}
            sx={{
              minWidth: "120px",
            }}
          >
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}