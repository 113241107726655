import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useBreadcrumbs } from "provider/BreadcrumbProvider";
import { licenceTypes } from "lib/utilities/globalConstants";
import { ChevronRight, Home } from "@mui/icons-material";

const AdminNavbar = () => {
  const { breadcrumbs } = useBreadcrumbs();
  const { pathname } = useLocation();

  return (
    <nav
      className="bg-white border-b border-gray-200 h-11 sticky top-0 z-50 flex justify-between px-5"
    >
      <ol className="flex items-center">
        <li className="flex items-center">
          <Link
            to="/admin/dashboard"
            className="p-2 rounded-lg text-gray-500 hover:text-blue-600 hover:bg-blue-50 transition-all duration-200 flex items-center"
            aria-label="Home"
          >
            <Home className="w-5 h-5" />
          </Link>
          <ChevronRight className="w-4 h-4 text-gray-700" />
        </li>
        {breadcrumbs?.map((path, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <li key={index} className="flex items-center">
              {!isLast ? (
                <>
                  <Link
                    to={path.link}
                    className="px-2 py-1 rounded-md text-gray-600 hover:text-blue-600 hover:bg-blue-50 transition-all duration-200 text-sm font-medium flex items-center"
                  >
                    {path.label}
                  </Link>
                  <ChevronRight className="w-4 h-4 text-gray-700" />
                </>
              ) : (
                  <span className="px-2 py-1 bg-gray-50 rounded-md text-gray-900 text-sm font-semibold">
                    {path.label}
                  </span>
              )}
            </li>
          );
        })}
      </ol>
      <div className="flex items-center">
        <div className="px-3 py-2 bg-blue-50 rounded-lg flex items-center space-x-2 group hover:bg-blue-100 transition-colors duration-200">
          <span className="text-blue-700 text-sm font-semibold whitespace-nowrap cursor-default">
            Dashboard {licenceTypes[pathname.split('/')[2]] || ""}
          </span>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;