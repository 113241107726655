import SignupComponent from "customComponents/SignupComponent";
import { constants } from "lib/utilities/Auth";
import { BASE_URL } from "lib/utilities/globalConstants";
// import { BASE_URL } from "lib/utilities/globalConstants";
import { signupSchema } from "lib/utilities/schemas/signupSchema";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Material Kit 2 React example components
function AdminSignup({ inviteId }) {
  const [credentials, setCredentials] = useState({
    fullName: "",
    gender: "",
    phone: "",
    dob: "",
    email: "",
    password: "",
  });
  const [dateString, setDateString] = useState("");
  const [creatingAccount, setCreatingAccount] = useState(false);
  const { regex } = constants;
  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "phone") {
      updatedValue = value.replace(regex.bannedSymbols.phoneNumber, "");
    } else if (e.target.type === "date") {
      setDateString(value);
      updatedValue = new Date(value);
    }

    setCredentials({ ...credentials, [name]: updatedValue });
  };

  const handleSignup = async () => {
    const validationResult = signupSchema.safeParse(credentials);
    if (validationResult.success) {
      try {
        setCreatingAccount(true);
        const response = await fetch(`${BASE_URL}admin/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteId: inviteId,
            displayName: credentials.fullName,
            email: credentials.email,
            password: credentials.password,
            gender: credentials.gender,
            phone: credentials.phone,
            dob: credentials.dob,
          }),
        });
        const data = await response.json();
        console.log(data);
        if (response.ok) {
          navigate("/admin/dashboard");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCreatingAccount(false);
      }

    } else {
      toast.error(
        <p style={{ fontSize: "16px" }}>
          {validationResult.error.errors[0].message || "Try Again"}
        </p>
      );
    }
  };

  return (
    <SignupComponent
      handleSignup={handleSignup}
      credentials={credentials}
      onChange={onChange}
      creatingAccount={creatingAccount}
      dateString={dateString}
    />
  );
}

AdminSignup.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

export default AdminSignup;
