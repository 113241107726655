/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Suspense, useEffect } from "react";
import { lazy } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import ConfigWrapper from "customComponents/ConfigWrapper";
import AdminLayout from "layouts/pages/admin";
import AdminSignupPage from "layouts/pages/admin/signup";
import ForgotPasswordPage from "layouts/pages/authentication/forgot-password";
import SignInPage from "layouts/pages/authentication/sign-in";
import SignUpPage from "layouts/pages/authentication/sign-up";
import CookiePolicyPage from "layouts/pages/landing-pages/cookie-policy";
import DataPrivacyPolicyPage from "layouts/pages/landing-pages/data-privacy-policy";
import DataRetentionPolicyPage from "layouts/pages/landing-pages/data-retention-policy";
import DataSubjectRightsPolicyPage from "layouts/pages/landing-pages/data-subject-rights-policy";
import SuperAdminLayout from "layouts/pages/super-admin";
import ApproveCustomSkills from "pages/SuperAdmin/ApproveCustomSkills";
import { AuthProvider } from "provider/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "webRoutes";
import "./index.css";
import SuperDashboard from "./pages/SuperAdmin/Dashboard/index";
import AdminHome from "pages/Admin/AdminHome";
import { CircularProgress } from "@mui/material";
import DashboardLayout from "layouts/pages/admin/dashboardLayout";
import { BreadcrumbProvider } from "provider/BreadcrumbProvider";

const CandidatePageV1 = lazy(() => import("pages/Admin/V1/Candidate"));
const AdminDashboardV1 = lazy(() => import("pages/Admin/V1/Dashboard"));
const FormPageV1 = lazy(() => import("pages/Admin/V1/FormPage"));
const CandidatePageV2 = lazy(() => import("pages/Admin/V2/Candidate"));
const AdminDashboardV2 = lazy(() => import("pages/Admin/V2/Dashboard"));
const FormPageV2 = lazy(() => import("pages/Admin/V2/FormPage"));

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.route} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <BreadcrumbProvider>
        <AuthProvider>
          <CssBaseline />
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme="light"
            limit={2}
          />
          <Routes>
            {getRoutes(routes)}
            <Route path="/signin/:origin?" element={<ConfigWrapper Component={<SignInPage />} />} />
            <Route path="/signup/:origin?" element={<ConfigWrapper Component={<SignUpPage />} />} />
            <Route
              path="/forgot-password/:origin?"
              element={<ConfigWrapper Component={<ForgotPasswordPage />} />}
            />
            <Route
              path="/techyrr-admin/signup/:origin?"
              element={<ConfigWrapper Component={<SignUpPage techyrrAdmin={true} />} />}
            />
            <Route
              path="/techyrr-admin/signin/:origin?"
              element={<ConfigWrapper Component={<SignInPage techyrrAdmin={true} />} />}
            />
            <Route
              path="/techyrr-admin/forgot-password/:origin?"
              element={<ConfigWrapper Component={<ForgotPasswordPage techyrrAdmin={true} />} />}
            />
            <Route path="/" element={<Presentation />} />
            <Route path="*" element={<Navigate to="/presentation" />} />
            <Route path="/admin/signup/:inviteId" element={<AdminSignupPage />} />
            {/* Super Admin */}
            <Route
              path="/super-admin/dashboard"
              element={<SuperAdminLayout component={<SuperDashboard />} />}
            />
            <Route
              path="/super-admin/approve-custom-skills/:companyId/:formId"
              element={<SuperAdminLayout component={<ApproveCustomSkills />} />}
            />
            {/* Admin */}
            <Route path="/admin/dashboard" element={<AdminLayout component={<AdminHome />} />} />
            {/* Admin V1 Licence */}
            <Route
              path="/admin/v1/dashboard"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<AdminDashboardV1 />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v1/dashboard/form/:formId"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<FormPageV1 />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v1/dashboard/form/:formId/candidate/:candidateId"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<CandidatePageV1 />} />
                    </Suspense>
                  }
                />
              }
            />
            {/* Admin V2 Licence */}
            <Route
              path="/admin/v2/dashboard"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<AdminDashboardV2 />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v2/dashboard/form/:formId"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<FormPageV2 />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v2/dashboard/form/:formId/candidate/:candidateId"
              element={
                <AdminLayout
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <DashboardLayout component={<CandidatePageV2 />} />
                    </Suspense>
                  }
                />
              }
            />
            {/* Policy */}
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />;
            <Route path="/data-privacy-policy" element={<DataPrivacyPolicyPage />} />
            <Route path="/data-retention-policy" element={<DataRetentionPolicyPage />} />
            <Route path="/data-subject-rights-policy" element={<DataSubjectRightsPolicyPage />} />
          </Routes>
        </AuthProvider>
      </BreadcrumbProvider>
    </ThemeProvider>
  );
}
